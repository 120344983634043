.app-container {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #e3e1e1;
  color: #333;
}

header {
  background-color: #282c34;
  color: #fdfdfd;
  padding: 20px;
}

h1 {
  font-size: 2.5em;
}

.about, .skills, .scripting, .platform {
  margin: 20px 0;
  padding: 10px;
  background-color: #e1e5e7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  font-size: 1.2em;
  margin: 10px 0;
}

.skills h2, .scripting h2, .about h2, .platform h2 {
  color: #4546a1; /* Фиолетовый цвет */
}

footer {
  margin-top: 40px;
}

.skills-platform {
  display: flex; /* Используем Flexbox для расположения элементов */
  justify-content: space-between; /* Распределяем пространство между элементами */
  margin-top: 20px; /* Добавляем отступ сверху */
}

.skills {
  flex: 1; /* Задаем размер для секции Skills */
  margin-right: 20px; /* Добавляем отступ справа для секции Skills */
  text-align: center; /* Центрируем заголовок секции */
}

.skills ul {
  text-align: left; /* Выровнять элементы списка по левому краю */
}

.platform-scripting {
  display: flex;
  flex-direction: column; /* Располагаем Platform и Scripting вертикально */
  flex: 1; /* Задаем равный размер для секции Platform и Scripting */
  margin-left: 20px; /* Добавляем отступ слева для секции Platform и Scripting */
}

.platform {
  margin-bottom: 10px; /* Добавляем отступ между секциями Platform и Scripting */
}
footer {
  margin-top: 40px;
  text-align: center; /* Выровнять текст по левому краю */
  max-width: 600px; /* Установить максимальную ширину для центрирования */
  margin-left: auto; /* Центрировать элемент */
  margin-right: auto; /* Центрировать элемент */
}

.skills {
  flex: 1; /* Задаем размер для секции Skills */
  margin-right: 20px; /* Добавляем отступ справа для секции Skills */
  text-align: center; /* Центрируем заголовок секции */
}

.skill-item {
  background-color: #e1e5e7; /* Цвет фона для рамочки */
  border: 1px solid #ccc; /* Цвет и стиль границы */
  border-radius: 8px; /* Закругление углов */
  padding: 10px; /* Отступ внутри рамочки */
  margin: 10px 0; /* Отступ между рамочками */
  text-align: left; /* Выровнять текст внутри рамочки по левому краю */
}
